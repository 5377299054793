// 压缩图片
export function compressImage(file: File, fileName: string, fileType: string, quality = 0.7): Promise<File> {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = ({ target }: any) => {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      const img = new Image()
      img.src = target.result as string
      img.onload = function () {
        const width = img.width
        canvas.width = width
        canvas.height = width * (img.height / img.width)
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        // 转换成base64格式 quality为图片压缩质量 0-1之间  值越小压缩的越大 图片质量越差
        // const data = canvas.toDataURL(file.type, quality)
        canvas.toBlob(
          (blob: any) => {
            // blob 转 file
            const file: File = new window.File([blob], fileName, { type: fileType })
            resolve(file)
          },
          fileType,
          quality
        )
      }
    }
  })
}

// 有些浏览器(如edge)不支持new File，所以为了兼容，base64要先转换成blob再设置type，name，lastModifiedDate
// 属性间接转换成文件，而不推荐直接new File()的方式
export const convertBase64UrlToImgFile = (urlData64: any, fileName: any, fileType: any) => {
  const urlData = urlData64.substring(urlData64.indexOf(',') + 1)
  const bytes = window.atob(urlData) //转换为byte
  //处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Int8Array(ab)
  let i
  for (i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  //转换成文件，添加文件的type，name，lastModifiedDate属性
  const blob: any = new Blob([ab], {
    type: fileType
  })
  blob.lastModifiedDate = new Date()
  blob.name = fileName
  return blob
}
